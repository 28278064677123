// client/src/pages/Blog.js
import React from "react";
import { Link } from "react-router-dom";
import technology from "../assets/technology.jpg";
import digital_marketing from "../assets/digital_marketing.jpg";
import design from "../assets/design.jpg";

const Blog = () => {
  const posts = [
    {
      id: 1,
      title: "The Future of Web Development",
      excerpt:
        "Exploring upcoming trends and technologies that will shape the future of web development.",
      category: "Technology",
      author: "John Doe",
      date: "2024-03-15",
      image: technology,
      readTime: "5 min read",
    },
    {
      id: 2,
      title: "Mastering Digital Marketing",
      excerpt:
        "Essential strategies and tips for successful digital marketing campaigns.",
      category: "Marketing",
      author: "Jane Smith",
      date: "2024-03-10",
      image: digital_marketing,
      readTime: "7 min read",
    },
    {
      id: 3,
      title: "UI/UX Design Principles",
      excerpt:
        "Key principles and best practices for creating user-friendly interfaces.",
      category: "Design",
      author: "Mike Johnson",
      date: "2024-03-05",
      image: design,
      readTime: "6 min read",
    },
    // Add more blog posts as needed
  ];

  const categories = ["All", "Technology", "Marketing", "Design", "Business"];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Blog Header */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Blog</h1>
        <p className="text-lg text-gray-600">
          Insights, news, and industry trends
        </p>
      </div>

      {/* Category Filter */}
      <div className="flex flex-wrap justify-center gap-4 mb-12">
        {categories.map((category) => (
          <button
            key={category}
            className="px-4 py-2 rounded-full border border-gray-300 hover:border-blue-500 hover:text-blue-500 transition-colors"
          >
            {category}
          </button>
        ))}
      </div>

      {/* Search Bar */}
      <div className="max-w-xl mx-auto mb-12">
        <input
          type="text"
          placeholder="Search articles..."
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        />
      </div>

      {/* Blog Posts Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {posts.map((post) => (
          <article
            key={post.id}
            className="bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <div className="flex items-center text-sm text-gray-500 mb-2">
                <span>{post.category}</span>
                <span className="mx-2">•</span>
                <span>{post.readTime}</span>
              </div>
              <h2 className="text-xl font-semibold text-gray-900 mb-2">
                {post.title}
              </h2>
              <p className="text-gray-600 mb-4">{post.excerpt}</p>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="text-sm">
                    <p className="text-gray-900 font-medium">{post.author}</p>
                    <p className="text-gray-500">
                      {new Date(post.date).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <Link
                  to={`/blog/${post.id}`}
                  className="text-blue-600 hover:text-blue-500"
                >
                  Read More →
                </Link>
              </div>
            </div>
          </article>
        ))}
      </div>

      {/* Pagination */}
      <div className="flex justify-center mt-12 space-x-2">
        <button className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50">
          Previous
        </button>
        <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
          1
        </button>
        <button className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50">
          2
        </button>
        <button className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50">
          3
        </button>
        <button className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50">
          Next
        </button>
      </div>
    </div>
  );
};

export default Blog;
