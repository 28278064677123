// client/src/pages/AdminDashboard.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("blogs");
  const [blogs, setBlogs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  const [blogForm, setBlogForm] = useState({
    title: "",
    content: "",
    image: "",
    category: "",
  });

  const [projectForm, setProjectForm] = useState({
    title: "",
    description: "",
    image: "",
    technologies: "",
    category: "",
    link: "",
  });

  const handleBlogSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/admin/blogs/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
        body: JSON.stringify(blogForm),
      });

      if (response.ok) {
        alert("Blog posted successfully!");
        setBlogForm({
          title: "",
          content: "",
          image: "",
          category: "",
        });
        fetchBlogs();
      }
    } catch (error) {
      console.error("Error posting blog:", error);
    }
  };

  const handleProjectSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/admin/projects/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
        body: JSON.stringify(projectForm),
      });

      if (response.ok) {
        alert("Project added successfully!");
        setProjectForm({
          title: "",
          description: "",
          image: "",
          technologies: "",
          category: "",
          link: "",
        });
        fetchProjects();
      }
    } catch (error) {
      console.error("Error adding project:", error);
    }
  };

  const fetchBlogs = async () => {
    try {
      const response = await fetch("/api/blogs");
      const data = await response.json();
      setBlogs(data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch("/api/projects");
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchProjects();
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow p-6">
          {/* Navigation Tabs */}
          <div className="flex space-x-4 mb-6">
            <button
              onClick={() => setActiveTab("blogs")}
              className={`px-4 py-2 rounded ${
                activeTab === "blogs" ? "bg-blue-600 text-white" : "bg-gray-200"
              }`}
            >
              Manage Blogs
            </button>
            <button
              onClick={() => setActiveTab("projects")}
              className={`px-4 py-2 rounded ${
                activeTab === "projects"
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200"
              }`}
            >
              Manage Projects
            </button>
          </div>

          {/* Blog Management Section */}
          {activeTab === "blogs" && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Add New Blog Post</h2>
              <form onSubmit={handleBlogSubmit} className="space-y-4">
                <div>
                  <label className="block mb-1">Title</label>
                  <input
                    type="text"
                    value={blogForm.title}
                    onChange={(e) =>
                      setBlogForm({ ...blogForm, title: e.target.value })
                    }
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-1">Content</label>
                  <textarea
                    value={blogForm.content}
                    onChange={(e) =>
                      setBlogForm({ ...blogForm, content: e.target.value })
                    }
                    className="w-full p-2 border rounded h-32"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-1">Image URL</label>
                  <input
                    type="text"
                    value={blogForm.image}
                    onChange={(e) =>
                      setBlogForm({ ...blogForm, image: e.target.value })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block mb-1">Category</label>
                  <input
                    type="text"
                    value={blogForm.category}
                    onChange={(e) =>
                      setBlogForm({ ...blogForm, category: e.target.value })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  Post Blog
                </button>
              </form>

              {/* Existing Blogs List */}
              <div className="mt-8">
                <h3 className="text-xl font-bold mb-4">Existing Blog Posts</h3>
                <div className="space-y-4">
                  {blogs.map((blog) => (
                    <div key={blog.id} className="border p-4 rounded">
                      <h4 className="font-bold">{blog.title}</h4>
                      <p className="text-gray-600">{blog.category}</p>
                      <div className="mt-2 flex space-x-2">
                        <button className="text-blue-600 hover:underline">
                          Edit
                        </button>
                        <button className="text-red-600 hover:underline">
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Project Management Section */}
          {activeTab === "projects" && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Add New Project</h2>
              <form onSubmit={handleProjectSubmit} className="space-y-4">
                <div>
                  <label className="block mb-1">Title</label>
                  <input
                    type="text"
                    value={projectForm.title}
                    onChange={(e) =>
                      setProjectForm({ ...projectForm, title: e.target.value })
                    }
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-1">Description</label>
                  <textarea
                    value={projectForm.description}
                    onChange={(e) =>
                      setProjectForm({
                        ...projectForm,
                        description: e.target.value,
                      })
                    }
                    className="w-full p-2 border rounded h-32"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-1">Image URL</label>
                  <input
                    type="text"
                    value={projectForm.image}
                    onChange={(e) =>
                      setProjectForm({ ...projectForm, image: e.target.value })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block mb-1">
                    Technologies (comma-separated)
                  </label>
                  <input
                    type="text"
                    value={projectForm.technologies}
                    onChange={(e) =>
                      setProjectForm({
                        ...projectForm,
                        technologies: e.target.value,
                      })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block mb-1">Category</label>
                  <input
                    type="text"
                    value={projectForm.category}
                    onChange={(e) =>
                      setProjectForm({
                        ...projectForm,
                        category: e.target.value,
                      })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block mb-1">Project Link</label>
                  <input
                    type="text"
                    value={projectForm.link}
                    onChange={(e) =>
                      setProjectForm({ ...projectForm, link: e.target.value })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                  Add Project
                </button>
              </form>

              {/* Existing Projects List */}
              <div className="mt-8">
                <h3 className="text-xl font-bold mb-4">Existing Projects</h3>
                <div className="space-y-4">
                  {projects.map((project) => (
                    <div key={project.id} className="border p-4 rounded">
                      <h4 className="font-bold">{project.title}</h4>
                      <p className="text-gray-600">{project.category}</p>
                      <div className="mt-2 flex space-x-2">
                        <button className="text-blue-600 hover:underline">
                          Edit
                        </button>
                        <button className="text-red-600 hover:underline">
                          Delete
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
