// client/src/pages/Portfolio.js
import React from "react";
import web from "../assets/web.jpg";

const Portfolio = () => {
  const projects = [
    {
      id: 1,
      title: "E-Commerce Platform",
      category: "Web Development",
      image: web,
      description:
        "A full-featured e-commerce solution built with React and Node.js",
      technologies: ["React", "Node.js", "MongoDB", "Express"],
      client: "RetailCo",
    },
    {
      id: 2,
      title: "Mobile Banking App",
      category: "Mobile Development",
      image: "/api/placeholder/600/400",
      description: "Secure and user-friendly mobile banking application",
      technologies: ["React Native", "Firebase", "Redux"],
      client: "FinBank",
    },
    {
      id: 3,
      title: "Healthcare Management System",
      category: "Web Application",
      image: "/api/placeholder/600/400",
      description: "Comprehensive healthcare management solution",
      technologies: ["React", "Python", "PostgreSQL"],
      client: "MediCare",
    },
    // Add more projects as needed
  ];

  const categories = [
    "All",
    "Web Development",
    "Mobile Development",
    "Web Application",
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Portfolio Header */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Portfolio</h1>
        <p className="text-lg text-gray-600">
          Explore our latest works and projects
        </p>
      </div>

      {/* Category Filter */}
      <div className="flex flex-wrap justify-center gap-4 mb-12">
        {categories.map((category) => (
          <button
            key={category}
            className="px-4 py-2 rounded-full border border-gray-300 hover:border-blue-500 hover:text-blue-500 transition-colors"
          >
            {category}
          </button>
        ))}
      </div>

      {/* Projects Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map((project) => (
          <div
            key={project.id}
            className="bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <img
              src={project.image}
              alt={project.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <span className="text-sm font-medium text-blue-600">
                {project.category}
              </span>
              <h3 className="text-xl font-semibold text-gray-900 mt-2">
                {project.title}
              </h3>
              <p className="mt-2 text-gray-600">{project.description}</p>
              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-900">
                  Technologies:
                </h4>
                <div className="flex flex-wrap gap-2 mt-2">
                  {project.technologies.map((tech) => (
                    <span
                      key={tech}
                      className="px-2 py-1 text-sm bg-gray-100 rounded"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
              <button className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors">
                View Details
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
