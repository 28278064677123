import about from "../assets/about_us.jpg";
const About = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">About Us</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
          <p className="text-gray-600 mb-4">
            We're a team of passionate technologists dedicated to turning
            innovative ideas into reality. With a focus on machine learning,
            augmented reality, and database systems, we've honed our skills to
            deliver impactful solutions across various industries.
          </p>
          <p className="text-gray-600">
            From small-scale projects to large-scale applications, we pride
            ourselves on our ability to tackle complex challenges and provide
            creative solutions. Our goal is to make technology accessible and
            meaningful, whether it's through educational initiatives or
            cutting-edge app development.
          </p>
          <p className="mt-1 text-gray-700">
            Join us as we continue to explore the frontiers of technology and
            help shape the future.
          </p>
        </div>
        <div>
          <img src={about} alt="About Us" className="rounded-lg shadow-lg" />
        </div>
      </div>
    </div>
  );
};

export default About;
