import { useState, useEffect } from "react";
import axios from "axios";
import Hero from "../components/Hero";
import ServicesList from "../components/ServicesList";
import LatestPosts from "../components/LatestPosts";
import FeaturedProjects from "../components/FeaturedProjects";

const Home = () => {
  const [content, setContent] = useState({
    services: [],
    posts: [],
    projects: [],
  });

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const [services, posts, projects] = await Promise.all([
          axios.get("/api/services"),
          axios.get("/api/posts?limit=3"),
          axios.get("/api/projects?limit=4"),
        ]);

        setContent({
          services: services.data,
          posts: posts.data,
          projects: projects.data,
        });
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);

  return (
    <div className="space-y-12">
      <Hero />
      <ServicesList services={content.services} />
      <FeaturedProjects projects={content.projects} />
      <LatestPosts posts={content.posts} />
    </div>
  );
};

export default Home;
