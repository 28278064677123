const ServicesList = ({ services = [] }) => {
  const defaultServices = [
    {
      title: "Web Development",
      description:
        "Custom websites built with modern technologies and best practices.",
      icon: "🌐",
    },
    {
      title: "Mobile App Development",
      description:
        "Tailored mobile applications crafted with cutting-edge technologies and industry best practices to meet your unique needs.",
      icon: "📱",
    },
    {
      title: "AI-Powered Solutions",
      description:
        "Innovative AI services, including machine learning models and intelligent systems, designed to drive smarter decisions and optimize processes.",
      icon: "🤖",
    },
    {
      title: "Embedded Systems & Circuit Design",
      description:
        "Custom embedded systems and circuit designs that power innovative devices and streamline operations with precision engineering.",
      icon: "🔌",
    },
    {
      title: "IoT Solutions",
      description:
        "Smart IoT systems that connect devices and streamline operations, empowering businesses with real-time data and automation.",
      icon: "🏠",
    },

    {
      title: "Digital Marketing",
      description:
        "Strategic marketing solutions to grow your online presence.",
      icon: "📈",
    },
    {
      title: "Brand Design",
      description:
        "Create a memorable brand identity that resonates with your audience.",
      icon: "🎨",
    },
    {
      title: "Content Creation",
      description: "Engaging content that tells your story and drives results.",
      icon: "✍️",
    },
  ];

  const displayServices = services.length > 0 ? services : defaultServices;

  return (
    <section className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Our Services
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Comprehensive solutions for your business needs
          </p>
        </div>

        <div className="mt-12 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {displayServices.map((service, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-6 hover:transform hover:-translate-y-1 transition-transform duration-300"
            >
              <div className="text-4xl mb-4">{service.icon}</div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesList;
