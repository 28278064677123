import { Link } from "react-router-dom";
import web from "../assets/technology.jpg";
import design from "../assets/design.jpg";
import digital from "../assets/digital_marketing.jpg";

const LatestPosts = ({ posts = [] }) => {
  const defaultPosts = [
    {
      id: 1,
      title: "The Future of Web Development",
      excerpt: "Exploring upcoming trends and technologies in web development.",
      date: "2024-03-15",
      author: "John Doe",
      image: web,
    },
    {
      id: 2,
      title: "Effective Digital Marketing Strategies",
      excerpt: "Learn how to create impactful digital marketing campaigns.",
      date: "2024-03-10",
      author: "Jane Smith",
      image: digital,
    },
    {
      id: 3,
      title: "Design Trends for 2024",
      excerpt: "Discover the latest trends in web and graphic design.",
      date: "2024-03-05",
      author: "Mike Johnson",
      image: design,
    },
  ];

  const displayPosts = posts.length > 0 ? posts : defaultPosts;

  return (
    <section className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Latest Blog Posts
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Stay updated with our latest insights and news
          </p>
        </div>

        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {displayPosts.map((post) => (
            <div
              key={post.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <div className="text-sm text-gray-500 mb-2">
                  {new Date(post.date).toLocaleDateString()} • {post.author}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {post.title}
                </h3>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <Link
                  to={`/blog/${post.id}`}
                  className="text-blue-600 hover:text-blue-500"
                >
                  Read More →
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <Link
            to="/blog"
            className="inline-block bg-blue-600 text-white px-8 py-3 rounded-md hover:bg-blue-700 transition duration-300"
          >
            View All Posts
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LatestPosts;
