import { Link } from "react-router-dom";
import office from "../assets/web.jpg";
import ecommerce from "../assets/e_commerce.png";
import brand from "../assets/branding.jpg";
import construction from "../assets/construction.jpg";

const FeaturedProjects = ({ projects = [] }) => {
  const defaultProjects = [
    {
      id: 0,
      title: "Office Management System",
      description:
        "A robust office management solution designed to streamline daily operations and enhance team collaboration.",
      image: office,
      category: "Web Development",
    },
    {
      id: 1,
      title: "E-Commerce Platform",
      description:
        "A cutting-edge e-commerce platform featuring advanced functionalities for seamless online shopping experiences.",
      image: ecommerce,
      category: "Web Development",
    },
    {
      id: 2,
      title: "Brand Redesign",
      description:
        "A comprehensive brand identity overhaul for a tech startup, focusing on modern aesthetics and brand recognition.",
      image: brand,
      category: "Branding",
    },
    {
      id: 3,
      title: "Construction Mobile App",
      description:
        "A cross-platform mobile app designed to help construction professionals easily perform key calculations on the go.",
      image: construction,
      category: "App Development",
    },
  ];

  const displayProjects = projects.length > 0 ? projects : defaultProjects;

  return (
    <section className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Featured Projects
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Some of our best work that showcases our capabilities
          </p>
        </div>

        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {displayProjects.map((project) => (
            <div
              key={project.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <span className="text-sm font-medium text-blue-600">
                  {project.category}
                </span>
                <h3 className="mt-2 text-xl font-semibold text-gray-900">
                  {project.title}
                </h3>
                <p className="mt-2 text-gray-600">{project.description}</p>
                <Link
                  to={`/portfolio/${project.id}`}
                  className="mt-4 inline-block text-blue-600 hover:text-blue-500"
                >
                  View Project →
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <Link
            to="/portfolio"
            className="inline-block bg-blue-600 text-white px-8 py-3 rounded-md hover:bg-blue-700 transition duration-300"
          >
            View All Projects
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FeaturedProjects;
