const Services = () => {
  const services = [
    {
      title: "Web Development",
      description: "Custom website development using modern technologies.",
    },
    {
      title: "Digital Marketing",
      description: "SEO, social media, and content marketing strategies.",
    },
    {
      title: "Branding",
      description: "Brand identity design and development.",
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Our Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
            <p className="text-gray-600">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
